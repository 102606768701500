import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getCasinoPlExposure,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";

import CasinoPage from "./components/CasinoPage";
export default function DragonTiger() {
  const GAME_ID = "DRAGON_TIGER_20";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("DRAGON");
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);

  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.dragonTiger2020}
      GAME_ID={GAME_ID}
      shortName={"dt20"}
      gameName={"2020 DRAGON TIGER"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-0 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="d-lg-block d-none ng-tns-c1798067373-0"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-full-box ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="dt20-odd-box dt20dragon ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds text-center ng-tns-c1798067373-0"
              >
                {getOddForEntity("Dragon", casinoData, true)}
              </div>
              <div
                onClick={() => setBet("Dragon", casinoData, dispatch)}
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-row"
                    : ""
                }`}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  Dragon
                </span>
              </div>
              <p
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
              >
                {" "}
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Dragon",
                  casinoData?.data?.response?.marketId
                )}{" "}
              </p>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="dt20-odd-box dt20tie ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds text-center ng-tns-c1798067373-0"
              >
                {getOddForEntity("Tie", casinoData, true)}
              </div>
              <div
                onClick={() => setBet("Tie", casinoData, dispatch)}
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-row"
                    : ""
                }`}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  Tie
                </span>
              </div>
              <p
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
              >
                {" "}
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Tie",
                  casinoData?.data?.response?.marketId
                )}{" "}
              </p>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="dt20-odd-box dt20tiger ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds text-center ng-tns-c1798067373-0"
              >
                {getOddForEntity("Tiger", casinoData, true)}
              </div>
              <div
                onClick={() => setBet("Tiger", casinoData, dispatch)}
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-row"
                    : ""
                }`}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  Tiger
                </span>
              </div>
              <p
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
              >
                {" "}
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Tiger",
                  casinoData?.data?.response?.marketId
                )}{" "}
              </p>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="dt20-odd-box dt20pair ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds text-center ng-tns-c1798067373-0"
              >
                {getOddForEntity("Pair", casinoData, true)}
              </div>
              <div
                onClick={() => setBet("Pair", casinoData, dispatch)}
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-row"
                    : ""
                }`}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  Pair
                </span>
              </div>
              <p
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
              >
                {" "}
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Pair",
                  casinoData?.data?.response?.marketId
                )}{" "}
              </p>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="col-md-12 min-max-dt20 text-end ng-tns-c1798067373-0"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                <b
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  Min:{" "}
                </b>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  {" "}
                  100{" "}
                </span>
              </span>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                <b
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  Max:{" "}
                </b>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  300000
                </span>
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box mt-3 ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-left-box ng-tns-c1798067373-0"
            >
              <h4
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center mb-2 ng-tns-c1798067373-0"
              >
                DRAGON
              </h4>
              <div
                _ngcontent-ng-c1798067373=""
                className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                >
                  {getOddForEntity("Dragon Even", casinoData, true)}
                </div>
                <div
                  onClick={() => setBet("Dragon Even", casinoData, dispatch)}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                  >
                    Even
                  </span>
                </div>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Even",
                    casinoData?.data?.response?.marketId
                  )}{" "}
                </p>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                >
                  {getOddForEntity("Dragon Odd", casinoData, true)}
                </div>
                <div
                  onClick={() => setBet("Dragon Odd", casinoData, dispatch)}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                  >
                    Odd
                  </span>
                </div>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Odd",
                    casinoData?.data?.response?.marketId
                  )}{" "}
                </p>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="col-md-12 min-max-dt20 text-end ng-tns-c1798067373-0 ng-star-inserted"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Min:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    {" "}
                    100
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Max:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    {" "}
                    300000
                  </span>
                </span>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                >
                  {getOddForEntity("Dragon Red", casinoData, true)}
                </div>
                <div
                  onClick={() => setBet("Dragon Red", casinoData, dispatch)}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Dragon Red",
                    casinoData?.data?.response?.marketId
                  )}{" "}
                </p>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                >
                  {getOddForEntity("Dragon Black", casinoData, true)}
                </div>
                <div
                  onClick={() => setBet("Dragon Black", casinoData, dispatch)}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Dragon Black",
                    casinoData?.data?.response?.marketId
                  )}{" "}
                </p>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="col-md-12 min-max-dt20 text-end ng-tns-c1798067373-0 ng-star-inserted"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Min:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    100
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Max:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    300000
                  </span>
                </span>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-right-box ng-tns-c1798067373-0"
            >
              <h4
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center mb-2 ng-tns-c1798067373-0"
              >
                TIGER
              </h4>
              <div
                _ngcontent-ng-c1798067373=""
                className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                >
                  {getOddForEntity("Tiger Even", casinoData, true)}
                </div>
                <div
                  onClick={() => setBet("Tiger Even", casinoData, dispatch)}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                  >
                    Even
                  </span>
                </div>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Tiger Even",
                    casinoData?.data?.response?.marketId
                  )}{" "}
                </p>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                >
                  {getOddForEntity("Tiger Odd", casinoData, true)}
                </div>
                <div
                  onClick={() => setBet("Tiger Odd", casinoData, dispatch)}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                  >
                    Odd
                  </span>
                </div>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Tiger Odd",
                    casinoData?.data?.response?.marketId
                  )}{" "}
                </p>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="col-md-12 min-max-dt20 text-end ng-tns-c1798067373-0 ng-star-inserted"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Min:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    {" "}
                    100
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Max:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    {" "}
                    300000
                  </span>
                </span>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                >
                  {getOddForEntity("Tiger Red", casinoData, true)}
                </div>
                <div
                  onClick={() => setBet("Tiger Red", casinoData, dispatch)}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Tiger Red",
                    casinoData?.data?.response?.marketId
                  )}{" "}
                </p>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                >
                  {getOddForEntity("Tiger Black", casinoData, true)}
                </div>
                <div
                  onClick={() => setBet("Tiger Black", casinoData, dispatch)}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Tiger Black",
                    casinoData?.data?.response?.marketId
                  )}{" "}
                </p>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="col-md-12 min-max-dt20 text-end ng-tns-c1798067373-0 ng-star-inserted"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Min:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    100
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Max:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    300000
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box mt-3 ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-left-box ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="dt20cards ng-tns-c1798067373-0"
              >
                <h4
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center mb-2 ng-tns-c1798067373-0"
                >
                  {" "}
                  DRAGON 12.00{" "}
                </h4>
                {CARD_ARRAY?.map((card) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    onClick={() =>
                      setBet(`Dragon Card ${card}`, casinoData, dispatch)
                    }
                    className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className={`${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      } ng-tns-c1798067373-0`}
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${card}.webp`}
                      />
                    </div>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                    >
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Dragon Card " + card,
                        casinoData?.data?.response?.marketId
                      )}
                    </p>
                  </div>
                ))}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="col-md-12 min-max-dt20 text-end ng-tns-c1798067373-0"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Min:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    100
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Max:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    300000
                  </span>
                </span>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-right-box ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="dt20cards ng-tns-c1798067373-0"
              >
                <h4
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-center mb-2 ng-tns-c1798067373-0"
                >
                  {" "}
                  TIGER 12.00{" "}
                </h4>
                {CARD_ARRAY?.map((card) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    onClick={() =>
                      setBet(`Tiger Card ${card}`, casinoData, dispatch)
                    }
                    className={` card-odd-box ng-tns-c1798067373-0 ng-star-inserted`}
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className={`${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      } ng-tns-c1798067373-0`}
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${card}.webp`}
                      />
                    </div>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                    >
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Tiger Card " + card,
                        casinoData?.data?.response?.marketId
                      )}
                    </p>
                  </div>
                ))}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="col-md-12 min-max-dt20 text-end ng-tns-c1798067373-0"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Min:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    100
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Max:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    300000
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="d-lg-none d-block ng-tns-c1798067373-0"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-full-box ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="dt20-odd-box dt20dragon ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds text-center ng-tns-c1798067373-0"
              >
                {getOddForEntity("Dragon", casinoData, true)}
              </div>
              <div
                onClick={() => setBet("Dragon", casinoData, dispatch)}
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-row"
                    : ""
                }`}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  Dragon
                </span>
              </div>
              <p
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
              >
                {" "}
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Dragon",
                  casinoData?.data?.response?.marketId
                )}{" "}
              </p>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="dt20-odd-box dt20tie ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds text-center ng-tns-c1798067373-0"
              >
                {getOddForEntity("Tie", casinoData, true)}
              </div>
              <div
                onClick={() => setBet("Tie", casinoData, dispatch)}
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-row"
                    : ""
                }`}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  Tie
                </span>
              </div>
              <p
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
              >
                {" "}
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Tie",
                  casinoData?.data?.response?.marketId
                )}{" "}
              </p>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="dt20-odd-box dt20tiger ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds text-center ng-tns-c1798067373-0"
              >
                {getOddForEntity("Tiger", casinoData, true)}
              </div>
              <div
                onClick={() => setBet("Tiger", casinoData, dispatch)}
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-row"
                    : ""
                }`}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  Tiger
                </span>
              </div>
              <p
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
              >
                {" "}
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Tiger",
                  casinoData?.data?.response?.marketId
                )}{" "}
              </p>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="dt20-odd-box dt20pair ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds text-center ng-tns-c1798067373-0"
              >
                {getOddForEntity("Pair", casinoData, true)}
              </div>
              <div
                onClick={() => setBet("Pair", casinoData, dispatch)}
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-row"
                    : ""
                }`}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  Pair
                </span>
              </div>
              <p
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
              >
                {" "}
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Pair",
                  casinoData?.data?.response?.marketId
                )}{" "}
              </p>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="col-12 min-max-dt20 text-end w- ng-tns-c1798067373-0"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                <b
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  Min:{" "}
                </b>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  100
                </span>
              </span>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                <b
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  Max:{" "}
                </b>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  300000
                </span>
              </span>
            </div>
          </div>
          <ul
            _ngcontent-ng-c1798067373=""
            className="nav nav-tabs menu-tabs ng-tns-c1798067373-0"
          >
            <li
              onClick={() => setSelectedType("Dragon")}
              _ngcontent-ng-c1798067373=""
              className="nav-item ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 ${
                  selectedType?.toLowerCase() == "dragon" ? "active" : ""
                } nav-link`}
              >
                {" "}
                Dragon{" "}
              </div>
            </li>
            <li
              onClick={() => setSelectedType("Tiger")}
              _ngcontent-ng-c1798067373=""
              className="nav-item ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-0 ${
                  selectedType?.toLowerCase() == "tiger" ? "active" : ""
                } nav-link`}
              >
                {" "}
                Tiger{" "}
              </div>
            </li>
          </ul>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-0 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-box mt-3 ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-left-box ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds text-center ng-tns-c1798067373-0"
                  >
                    {getOddForEntity(
                      selectedType + " Even",
                      casinoData,
                      true
                    )}
                  </div>
                  <div
                    onClick={() =>
                      setBet(selectedType + " Even", casinoData, dispatch)
                    }
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-row"
                        : ""
                    }`}
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      Even
                    </span>
                  </div>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                  >
                    {" "}
                    {getCasinoPlExposureByRunner(
                      casinoPlData,
                      selectedType + " Even",
                      casinoData?.data?.response?.marketId
                    )}{" "}
                  </p>
                </div>

                <div
                  _ngcontent-ng-c1798067373=""
                  className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds text-center ng-tns-c1798067373-0"
                  >
                    {getOddForEntity(selectedType + " Odd", casinoData, true)}
                  </div>
                  <div
                    onClick={() =>
                      setBet(selectedType + " Odd", casinoData, dispatch)
                    }
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-row"
                        : ""
                    }`}
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      Odd
                    </span>
                  </div>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                  >
                    {" "}
                    {getCasinoPlExposureByRunner(
                      casinoPlData,
                      selectedType + " Odd",
                      casinoData?.data?.response?.marketId
                    )}{" "}
                  </p>
                </div>

                <div
                  _ngcontent-ng-c1798067373=""
                  className="col-12 min-max-dt20 text-end ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Min:{" "}
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      100
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Max:{" "}
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      300000
                    </span>
                  </span>
                </div>

                <div
                  _ngcontent-ng-c1798067373=""
                  className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds text-center ng-tns-c1798067373-0"
                  >
                    {getOddForEntity(selectedType + " Red", casinoData, true)}
                  </div>
                  <div
                    onClick={() =>
                      setBet(selectedType + " Red", casinoData, dispatch)
                    }
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-row"
                        : ""
                    }`}
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-0"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-red ng-tns-c1798067373-0"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                          />
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-0"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-red ng-tns-c1798067373-0"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                  >
                    {" "}
                    {getCasinoPlExposureByRunner(
                      casinoPlData,
                      selectedType + " Red",
                      casinoData?.data?.response?.marketId
                    )}{" "}
                  </p>
                </div>

                <div
                  _ngcontent-ng-c1798067373=""
                  className="dt20-odd-box dt20odds ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds text-center ng-tns-c1798067373-0"
                  >
                    {getOddForEntity(
                      selectedType + " Black",
                      casinoData,
                      true
                    )}
                  </div>
                  <div
                    onClick={() =>
                      setBet(selectedType + " Black", casinoData, dispatch)
                    }
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-row"
                        : ""
                    }`}
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-0"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-red ng-tns-c1798067373-0"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                          />
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-0"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-red ng-tns-c1798067373-0"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                  >
                    {" "}
                    {getCasinoPlExposureByRunner(
                      casinoPlData,
                      selectedType + " Black",
                      casinoData?.data?.response?.marketId
                    )}{" "}
                  </p>
                </div>

                <div
                  _ngcontent-ng-c1798067373=""
                  className="col-12 min-max-dt20 text-end ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Min:{" "}
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      100
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Max:{" "}
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      300000
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-box mt-3 ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-left-box ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="dt20cards ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds text-center w-100 mb-1 ng-tns-c1798067373-0"
                  >
                    {" "}
                    12.00{" "}
                  </div>
                  {CARD_ARRAY.map((card, index) => (
                    <div
                      onClick={() =>
                        setBet(
                          selectedType + " Card " + card,
                          casinoData,
                          dispatch
                        )
                      }
                      _ngcontent-ng-c1798067373=""
                      className={`${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      } card-odd-box ng-tns-c1798067373-0 ng-star-inserted`}
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${card}.webp`}
                        />
                      </div>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                      >
                        {getCasinoPlExposureByRunner(
                          casinoPlData,
                          selectedType + " Card " + card,
                          casinoData?.data?.response?.marketId
                        )}
                      </p>
                    </div>
                  ))}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="col-12 min-max-dt20 text-end ng-tns-c1798067373-0"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Min:{" "}
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      100
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Max:{" "}
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      300000
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
