import React from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { CASINO_VIDEO, setBet } from "./CasinoGeneric";
import CasinoPage from "./components/CasinoPage";
import { getCasinoOddsAPI } from "../../service/casino";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
export default function WorliMatka() {
  const GAME_ID = "WORLI2";
  const VIDEO_ID = CASINO_VIDEO.instantWorli;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.instantWorli}
      GAME_ID={GAME_ID}
      shortName={"worli"}
      gameName={"INSTANT WORLI"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-9"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="d-xl-block d-none w-100 ng-tns-c1798067373-9"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-9 worlibox suspended-box"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="worli-left ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="worli-box-title ng-tns-c1798067373-9"
              >
                <b
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {" "}
                  9{" "}
                </b>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="worli-box-row ng-tns-c1798067373-9"
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item) => (
                  <div
                    onClick={() => setBet(String(item), casinoData, dispatch)}
                    _ngcontent-ng-c1798067373=""
                    className={`worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted ${selectedEventForBet?.runnerName ===
                      item}`}
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="worli-odd ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      {item}
                    </span>
                  </div>
                ))}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="worli-box-row ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="worli-odd ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    6
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted"
                >
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="worli-odd ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    7
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted"
                >
                  {/**/}
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="worli-odd ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    8
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted"
                >
                  {/**/}
                  {/**/}
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="worli-odd ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    9
                  </span>
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted"
                >
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="worli-odd ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    0
                  </span>
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="worli-right ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="worli-box-title ng-tns-c1798067373-9 ng-star-inserted"
              >
                <b
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {" "}
                  9{" "}
                </b>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="worli-box-row ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      Line 1{" "}
                    </b>
                  </span>
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="d-block ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    1|2|3|4|5{" "}
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted"
                >
                  {/**/}
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      ODD{" "}
                    </b>
                  </span>
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="d-block ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    1|3|5|7|9{" "}
                  </span>
                  {/**/}
                </div>
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="worli-box-row ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      Line 2{" "}
                    </b>
                  </span>
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="d-block ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    6|7|8|9|0{" "}
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted"
                >
                  {/**/}
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      EVEN{" "}
                    </b>
                  </span>
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="d-block ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    2|4|6|8|0{" "}
                  </span>
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="text-end worli_welcome ng-tns-c1798067373-9"
          >
            <p _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-9">
              <b _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-9">
                welcome single
              </b>
            </p>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="d-xl-none d-block w-100 ng-tns-c1798067373-9"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="worlibox ng-tns-c1798067373-9"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="worli-box-title ng-tns-c1798067373-9"
            >
              <b _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-9">
                {" "}
                9
              </b>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className={`ng-tns-c1798067373-9 w-100 worli-left ${
                casinoData?.data?.response?.autoTime < 5 ? "suspended-box" : ""
              }`}
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="worli-box-row ng-tns-c1798067373-9"
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item) => (
                  <div
                    onClick={() => setBet(String(item), casinoData, dispatch)}
                    _ngcontent-ng-c1798067373=""
                    className={`${selectedEventForBet?.runnerName ===
                      item} worli-odd-box back ng-tns-c1798067373-9 ng-star-inserted`}
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="worli-odd ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      {item}
                    </span>
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                  </div>
                ))}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="worli-box-title mt-3 ng-tns-c1798067373-9"
            >
              <b
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-9 ng-star-inserted"
              >
                9{" "}
              </b>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className={`ng-tns-c1798067373-9 w-100 worli-right ${
                casinoData?.data?.response?.autoTime < 5 ? "suspended-box" : ""
              }`}
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="worli-box-row ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-9 ng-star-inserted"
                >
                  <span
                    onClick={() => setBet("LINE 1", casinoData, dispatch)}
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      Line 1{" "}
                    </b>
                  </span>
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="d-block ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    1|2|3|4|5
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                <div
                  onClick={() => setBet("tasODD", casinoData, dispatch)}
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-9 ng-star-inserted"
                >
                  {/**/}
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      ODD{" "}
                    </b>
                  </span>
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="d-block ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    1|3|5|7|9{" "}
                  </span>
                  {/**/}
                </div>
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-9 ng-star-inserted"
                >
                  <span
                    onClick={() => setBet("LINE 2", casinoData, dispatch)}
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      Line 2{" "}
                    </b>
                  </span>
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="d-block ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    6|7|8|9|0{" "}
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-9 ng-star-inserted"
                >
                  {/**/}
                  {/**/}
                  <span
                    onClick={() => setBet("EVEN", casinoData, dispatch)}
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      EVEN{" "}
                    </b>
                  </span>
                  {/**/}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="d-block ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    2|4|6|8|0{" "}
                  </span>
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
