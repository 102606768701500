import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useQuery } from "react-query";
import CasinoVideo from "./components/CasinoVideo";
import Timer from "./components/Timer";
import {
  CARD_ARRAY,
  cardArray,
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import PlaceBet from "./components/PlaceBet";
import { useDispatch } from "react-redux";
import PlacedBet from "./components/PlacedBet";
import CasinoPage from "./components/CasinoPage";
export default function AmarAkhbarAnthoany() {
  const GAME_ID = "AAA";
  const VIDEO_ID = CASINO_VIDEO.amarAkbarAnthony;
  const dispatch = useDispatch();
  const [casinoPlData, setCasinoPlData] = useState([]);

  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );

  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);

  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={VIDEO_ID}
      GAME_ID={GAME_ID}
      shortName={"aaa"}
      gameName={"Amar Akbar Anthony"}
    >
      <div className="casino-detail">
        <div className="casino-table">
          <div className="casino-table-box">
            <div className="casino-odd-box-container">
              <div className="casino-nation-name">
                A. Amar
                <div className="casino-nation-book d-md-none" />
              </div>
              <div
                className={`casino-odds-box back  ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Amar", casinoData, dispatch)}
              >
                <span className="casino-odds">
                  {getOddForEntity("Amar", casinoData, true)}
                </span>
              </div>
              <div
                className={`casino-odds-box lay  ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Amar", casinoData, dispatch)}
              >
                <span className="casino-odds">
                  {getOddForEntity("Amar", casinoData, false)}
                </span>
              </div>
              <div className="casino-nation-book text-center d-none d-md-block w-100">
                {getCasinoPlExposureByRunner(casinoPlData, "Amar")}
              </div>
            </div>
            <div className="casino-odd-box-container">
              <div className="casino-nation-name">
                B. Akbar
                <div className="casino-nation-book d-md-none" />
              </div>
              <div
                className={`casino-odds-box back  ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Akbar", casinoData, dispatch)}
              >
                <span className="casino-odds">
                  {getOddForEntity("Akbar", casinoData, true)}
                </span>
              </div>
              <div
                className={`casino-odds-box lay  ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Akbar", casinoData, dispatch)}
              >
                <span className="casino-odds">
                  {getOddForEntity("Akbar", casinoData, false)}
                </span>
              </div>
              <div className="casino-nation-book text-center d-none d-md-block w-100">
                {getCasinoPlExposureByRunner(casinoPlData, "Akbar")}
              </div>
            </div>
            <div className="casino-odd-box-container">
              <div className="casino-nation-name">
                C. Anthony
                <div className="casino-nation-book d-md-none" />
              </div>
              <div
                className={`casino-odds-box back  ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Anthony", casinoData, dispatch)}
              >
                <span className="casino-odds">
                  {getOddForEntity("Anthony", casinoData, true)}
                </span>
              </div>
              <div
                className={`casino-odds-box lay  ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Anthony", casinoData, dispatch)}
              >
                <span className="casino-odds">
                  {getOddForEntity("Anthony", casinoData, false)}
                </span>
              </div>
              <div className="casino-nation-book text-center d-none d-md-block w-100">
                {getCasinoPlExposureByRunner(casinoPlData, "Anthony")}
              </div>
            </div>
          </div>
          <div className="casino-table-box mt-3">
            <div className="casino-table-left-box">
              <div className="aaa-odd-box">
                <div className="casino-odds text-center">
                  {getOddForEntity("Even", casinoData, true)}
                </div>
                <div
                  className={`casino-odds-box back casino-odds-box-theme   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Even", casinoData, dispatch)}
                >
                  <span className="casino-odds">Even</span>
                </div>
                <div className="casino-nation-book text-center">
                  {getCasinoPlExposureByRunner(casinoPlData, "Even")}
                </div>
              </div>
              <div className="aaa-odd-box">
                <div className="casino-odds text-center">
                  {getOddForEntity("Odd", casinoData, true)}
                </div>
                <div
                  className={`casino-odds-box back casino-odds-box-theme   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Odd", casinoData, dispatch)}
                >
                  <span className="casino-odds">Odd</span>
                </div>
                <div className="casino-nation-book text-center">
                  {getCasinoPlExposureByRunner(casinoPlData, "Odd")}
                </div>
              </div>
            </div>
            <div className="casino-table-center-box">
              <div className="aaa-odd-box">
                <div className="casino-odds text-center">
                  {getOddForEntity("Red", casinoData, true)}
                </div>
                <div
                  className={`casino-odds-box back casino-odds-box-theme   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Red", casinoData, dispatch)}
                >
                  <div className="casino-odds">
                    <span className="card-icon ms-1">
                      <span className="card-red ">{"{"}</span>
                    </span>
                    <span className="card-icon ms-1">
                      <span className="card-red ">[</span>
                    </span>
                  </div>
                </div>

                <div className="casino-nation-book text-center">
                  {getCasinoPlExposureByRunner(casinoPlData, "Red")}
                </div>
              </div>
              <div className="aaa-odd-box">
                <div className="casino-odds text-center">
                  {getOddForEntity("Black", casinoData, true)}
                </div>
                <div
                  className={`casino-odds-box back casino-odds-box-theme   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Black", casinoData, dispatch)}
                >
                  <div className="casino-odds">
                    <span className="card-icon ms-1">
                      <span className="card-black ">{"}"}</span>
                    </span>
                    <span className="card-icon ms-1">
                      <span className="card-black ">]</span>
                    </span>
                  </div>
                </div>
                <div className="casino-nation-book text-center">
                  {getCasinoPlExposureByRunner(casinoPlData, "Black")}
                </div>
              </div>
            </div>
            <div className="casino-table-right-box">
              <div className="aaa-odd-box">
                <div className="casino-odds text-center">
                  {getOddForEntity("Under 7", casinoData, true)}
                </div>
                <div
                  className={`casino-odds-box back casino-odds-box-theme   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Under 7", casinoData, dispatch)}
                >
                  <span className="casino-odds">Under 7</span>
                </div>
                <div className="casino-nation-book text-center">
                  {getCasinoPlExposureByRunner(casinoPlData, "Under 7")}
                </div>
              </div>
              <div className="aaa-odd-box">
                <div className="casino-odds text-center">
                  {getOddForEntity("Over 7", casinoData, true)}
                </div>
                <div
                  className={`casino-odds-box back casino-odds-box-theme   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Over 7", casinoData, dispatch)}
                >
                  <span className="casino-odds">Over 7</span>
                </div>
                <div className="casino-nation-book text-center">
                  {getCasinoPlExposureByRunner(casinoPlData, "Over 7")}
                </div>
              </div>
            </div>
          </div>
          <div className="casino-table-full-box aaacards mt-3">
            <h4 className="w-100 text-center mb-2">
              <b>{getOddForEntity("Card A", casinoData, true)}</b>
            </h4>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card A", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/A.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card A")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 2", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/2.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 2")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 3", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/3.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 3")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 4", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/4.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 4")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 5", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/5.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 5")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 6", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/6.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 6")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 7", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/7.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 7")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 8", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/8.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 8")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 9", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/9.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 9")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 10", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/10.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 10")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card J", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/J.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card J")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card Q", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/Q.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card Q")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={`${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card K", casinoData, dispatch)}
              >
                <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/K.png" />
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Card K")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
