import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import CasinoPage from "./components/CasinoPage";
export default function TeenPattiOpen() {
  const GAME_ID = "TEEN_8";
  const VIDEO_ID = CASINO_VIDEO.openTeenPatti;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const splitFromNumber = (string) => {
    if (string && typeof string === "string")
      return string?.split(/(\d+)/)?.filter(Boolean);
    return "";
  };
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.openTeenPatti}
      GAME_ID={GAME_ID}
      shortName={"teenpattiopen"}
      gameName={"TEEN PATTI OPEN"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-8 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-full-box ng-tns-c1798067373-8"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-header ng-tns-c1798067373-8"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-nation-detail ng-tns-c1798067373-8"
            />
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box back ng-tns-c1798067373-8"
            >
              {" "}
              Back (Min: 100 {/**/}
              {/**/} Max: 100000 {/**/}
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box back ng-tns-c1798067373-8"
            >
              {" "}
              (Min: 100 {/**/}
              {/**/} Max: 100000 {/**/}
              {/**/}){" "}
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-body ng-tns-c1798067373-8"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    {" "}
                    Player {item}{" "}
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="patern-name ng-tns-c1798067373-8 ng-star-inserted"
                    >
                      {casinoData?.data?.response?.cards?.length > 0 &&
                        casinoData?.data?.response?.cards[index]?.map((_) => (
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-icon ms-2 ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              {_.slice(0, 1)}
                            </span>
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-black ng-tns-c1798067373-8"
                            >
                              <img
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8 ng-star-inserted"
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${_}.webp`}
                              />
                              {/**/}
                            </span>
                          </span>
                        ))}
                    </div>
                    {/**/}
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-row"
                      : ""
                  }`}
                >
                  <div
                    onClick={() =>
                      setBet(`Player ${item}`, casinoData, dispatch, "back")
                    }
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity(`Player ${item}`, casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        `Player ${item}`
                      )}
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      setBet(
                        `Player plus ${item}`,
                        casinoData,
                        dispatch,
                        "back"
                      )
                    }
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      Pair plus 1
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      0{" "}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
