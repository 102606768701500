import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useQuery } from "react-query";
import CasinoVideo from "./components/CasinoVideo";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import { useDispatch } from "react-redux";
import PlaceBet from "./components/PlaceBet";
import TimerGeneric from "./components/TimerGeneric";
import Timer from "./components/Timer";
import PlacedBet from "./components/PlacedBet";
import CasinoPage from "./components/CasinoPage";
export default function FiveCricket() {
  const GAME_ID = "CRICKET_V3";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <>
      <CasinoPage
        casino={casinoData?.data}
        VIDEO_ID={CASINO_VIDEO.fiveFiveCricket}
        GAME_ID={GAME_ID}
        shortName={"fiveCricket"}
        gameName={"Five Cricket"}
      >
        <div className="casino-detail detail-page-container position-relative">
          <div className="game-market market-2 ">
            <div className="market-title">
              <span>Bookmaker</span>
            </div>
            <div className="market-header">
              <div className="market-nation-detail">
                <span className="market-nation-name">Min: 100 Max: 5L</span>
              </div>
              <div className="market-odd-box back">
                <b>Back</b>
              </div>
              <div className="market-odd-box lay">
                <b>Lay</b>
              </div>
            </div>
            <div className="market-body " data-title="OPEN">
              {casinoData?.data?.response?.oddDetailsDTOS?.map(
                (data, index) => (
                  <div
                    className="market-row "
                    data-title={data?.status?.toUpperCase()}
                  >
                    <div className="market-nation-detail">
                      <span className="market-nation-name">
                        {data?.runnerName}
                      </span>
                      <div className="market-nation-book">
                        {getCasinoPlExposureByRunner(
                          casinoPlData,
                          data?.runnerName,
                          data?.selectionId
                        )}
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        setBet(
                          data?.runnerName,
                          casinoData,
                          dispatch,
                          "back",
                          "oddDetailsDTOS"
                        )
                      }
                      className={`market-odd-box back ${
                        data?.status != "ACTIVE" ? "suspended-box" : ""
                      }  `}
                    >
                      <span className="market-odd">{data?.back1}</span>
                      <span className="market-volume">{data?.backSize1}</span>
                    </div>
                    <div
                      onClick={() =>
                        setBet(
                          data?.runnerName,
                          casinoData,
                          dispatch,
                          "lay",

                          "oddDetailsDTOS"
                        )
                      }
                      className={`market-odd-box lay ${
                        data?.status != "ACTIVE" ? "suspended-box" : ""
                      }  `}
                    >
                      <span className="market-odd">{data?.lay1}</span>
                      <span className="market-volume">{data?.laySize1}</span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="game-market market-6">
            <div className="market-title">
              <span>Fancy</span>
            </div>
            <div className="market-header">
              <div className="market-nation-detail" />
              <div className="market-odd-box lay">
                <b>No</b>
              </div>
              <div className="market-odd-box back">
                <b>Yes</b>
              </div>
              <div className="fancy-min-max-box" />
            </div>
            <div className="market-body " data-title="OPEN">
              {casinoData?.data?.response?.fancyOddDetailsDTOS?.map(
                (data, index) => (
                  <div>
                    <div className="fancy-market " data-title={data?.status}>
                      <div className="market-row">
                        <div className="market-nation-detail">
                          <span className="market-nation-name pointer">
                            {data?.runnerName}
                          </span>
                          <div className="market-nation-book" />
                        </div>
                        <div
                          onClick={() =>
                            setBet(
                              data?.runnerName,
                              casinoData,
                              dispatch,
                              "lay",
                              "fancyOddDetailsDTOS"
                            )
                          }
                          className={`market-odd-box lay ${
                            data?.status != "ACTIVE" ? "suspended-box" : ""
                          }  `}
                        >
                          <span className="market-odd">{data?.lay1}</span>
                          <span className="market-volume">
                            {data?.laySize1}
                          </span>
                        </div>
                        <div
                          onClick={() =>
                            setBet(
                              data?.runnerName,
                              casinoData,
                              dispatch,
                              "back",
                              "fancyOddDetailsDTOS"
                            )
                          }
                          className={`market-odd-box back ${
                            data?.status != "ACTIVE" ? "suspended-box" : ""
                          }  `}
                        >
                          <span className="market-odd">{data?.back1}</span>
                          <span className="market-volume">
                            {data?.backSize1}
                          </span>
                        </div>
                        <div className="fancy-min-max-box">
                          <div className="fancy-min-max">
                            <span className="w-100 d-block">Min: 100</span>
                            <span className="w-100 d-block">Max: 1L</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="game-market market-6">
            <div className="market-title">
              <span>Fancy1</span>
            </div>
            <div className="row row10">
              <div className="col-md-6">
                <div className="market-header">
                  <div className="market-nation-detail" />
                  <div className="market-odd-box back">
                    <b>Back</b>
                  </div>
                  <div className="market-odd-box lay">
                    <b>Lay</b>
                  </div>
                  <div className="fancy-min-max-box" />
                </div>
              </div>
              <div className="col-md-6 d-none d-xl-block">
                <div className="market-header">
                  <div className="market-nation-detail" />
                  <div className="market-odd-box back">
                    <b>Back</b>
                  </div>
                  <div className="market-odd-box lay">
                    <b>Lay</b>
                  </div>
                  <div className="fancy-min-max-box" />
                </div>
              </div>
            </div>
            <div className="market-body " data-title="OPEN">
              {casinoData?.data?.response?.fancy2OddDetailsDTOS?.map(
                (data, index) => (
                  <div>
                    <div className="fancy-market " data-title={data?.status}>
                      <div className="market-row">
                        <div className="market-nation-detail">
                          <span className="market-nation-name pointer">
                            {data?.runnerName}
                          </span>
                          <div className="market-nation-book">
                            {getCasinoPlExposureByRunner(
                              casinoPlData,
                              data?.runnerName,
                              data?.selectionId
                            )}
                          </div>
                        </div>
                        <div
                          onClick={() =>
                            setBet(
                              data?.runnerName,
                              casinoData,
                              dispatch,
                              "back",
                              "fancy2OddDetailsDTOS"
                            )
                          }
                          className={`market-odd-box back ${
                            data?.status != "ACTIVE" ? "suspended-box" : ""
                          }  `}
                        >
                          <span className="market-odd">{data?.back1}</span>
                          <span className="market-volume">
                            {data?.backSize1}
                          </span>
                        </div>
                        <div
                          onClick={() =>
                            setBet(
                              data?.runnerName,
                              casinoData,
                              dispatch,
                              "lay",
                              "fancy2OddDetailsDTOS"
                            )
                          }
                          className={`market-odd-box lay ${
                            data?.status != "ACTIVE" ? "suspended-box" : ""
                          }  `}
                        >
                          <span className="market-odd">{data?.lay1}</span>
                          <span className="market-volume">
                            {data?.laySize1}
                          </span>
                        </div>
                        <div className="fancy-min-max-box">
                          <div className="fancy-min-max">
                            <span className="w-100 d-block">Min: 100</span>
                            <span className="w-100 d-block">Max: 1L</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="mt-2 w-100" />
        </div>
      </CasinoPage>
    </>
  );
}
