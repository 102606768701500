import React from "react";

export default function TeenPattiRules() {
  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-10"
    >
      <app-casino-game-rules
        _ngcontent-ng-c1798067373=""
        className="ng-tns-c1798067373-10"
        _nghost-ng-c1761710207=""
      >
        <div
          _ngcontent-ng-c1761710207=""
          className="sidebar-title ng-star-inserted"
        >
          <h4 _ngcontent-ng-c1761710207="">Rules</h4>
        </div>
        <div _ngcontent-ng-c1761710207="" className="my-bets ng-star-inserted">
          <div _ngcontent-ng-c1761710207="" className="table-responsive">
            <table
              _ngcontent-ng-c1761710207=""
              className="table table-bordered"
            >
              <thead _ngcontent-ng-c1761710207="">
                <tr _ngcontent-ng-c1761710207="">
                  <th
                    _ngcontent-ng-c1761710207=""
                    colSpan={2}
                    className="text-center"
                  >
                    Pair Plus
                  </th>
                </tr>
              </thead>
              <tbody _ngcontent-ng-c1761710207="">
                <tr _ngcontent-ng-c1761710207="">
                  <td _ngcontent-ng-c1761710207="">Pair</td>
                  <td _ngcontent-ng-c1761710207="">1 TO 1</td>
                </tr>
                <tr _ngcontent-ng-c1761710207="">
                  <td _ngcontent-ng-c1761710207="">Flush</td>
                  <td _ngcontent-ng-c1761710207="">1 TO 4</td>
                </tr>
                <tr _ngcontent-ng-c1761710207="">
                  <td _ngcontent-ng-c1761710207="">Straight</td>
                  <td _ngcontent-ng-c1761710207="">1 TO 6</td>
                </tr>
                <tr _ngcontent-ng-c1761710207="">
                  <td _ngcontent-ng-c1761710207="">Trio</td>
                  <td _ngcontent-ng-c1761710207="">1 TO 30</td>
                </tr>
                <tr _ngcontent-ng-c1761710207="">
                  <td _ngcontent-ng-c1761710207="">Straight Flush</td>
                  <td _ngcontent-ng-c1761710207="">1 TO 40</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/**/}
        {/**/}
        {/**/}
        {/**/}
        {/**/}
      </app-casino-game-rules>
    </div>
  );
}
