import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import CasinoPage from "./components/CasinoPage";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
export default function Poker20() {
  const GAME_ID = "POKER_20";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.poker2020}
      GAME_ID={GAME_ID}
      shortName={"cards32a poker-20"}
      gameName={"20-20 Poker"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-8 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-box ng-tns-c1798067373-8"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box ng-tns-c1798067373-8"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-8"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-8"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-0 ng-tns-c1798067373-8"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      Min:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      100 {/**/}
                      {/**/}
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-1 ng-tns-c1798067373-8"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      Max:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      100000 {/**/}
                      {/**/}
                    </span>
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-8"
              >
                Player A
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-8"
              >
                Player B
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-8"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    Winner
                  </div>
                  <span>
                    {getCasinoPlExposureByRunner(casinoPlData, "Winner")}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row  ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Winner", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Winner", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Winner", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Winner", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Winner", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Winner", casinoData, true, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    One Pair
                  </div>

                  <span>
                    {getCasinoPlExposureByRunner(casinoPlData, "One Pair")}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row  ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("One Pair", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("One Pair", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("One Pair", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("One Pair", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("One Pair", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("One Pair", casinoData, true, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    Two Pair
                  </div>
                  <span>
                    {getCasinoPlExposureByRunner(casinoPlData, "Two Pair")}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row  ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Two Pair", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Two Pair", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Two Pair", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Two Pair", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Two Pair", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Two Pair", casinoData, true, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    {" "}
                    Three of a Kind{" "}
                  </div>
                  <span>
                    {getCasinoPlExposureByRunner(
                      casinoPlData,
                      "Three of a Kind"
                    )}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row  ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Three of a Kind", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Three of a Kind", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity(
                        "Three of a Kind",
                        casinoData,
                        true,
                        true
                      )}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Three of a Kind", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Three of a Kind", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity(
                        "Three of a Kind",
                        casinoData,
                        true,
                        true
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    Straight
                  </div>
                  <span>
                    {getCasinoPlExposureByRunner(casinoPlData, "Straight")}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row  ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Straight", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Straight", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Straight", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Straight", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Straight", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Straight", casinoData, true, true)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box-divider ng-tns-c1798067373-8"
          />
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-right-box ng-tns-c1798067373-8"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header d-none d-md-flex ng-tns-c1798067373-8"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-8"
              />
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-8"
              >
                Player A
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-8"
              >
                Player B
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-8"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    Flush
                  </div>
                  <span>
                    {getCasinoPlExposureByRunner(casinoPlData, "Flush")}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row  ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Flush", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Flush", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Flush", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Flush", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Flush", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Flush", casinoData, true, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    Full House
                  </div>
                  <span>
                    {getCasinoPlExposureByRunner(casinoPlData, "Full House")}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row  ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Full House", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Full House", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Full House", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Full House", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Full House", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity("Full House", casinoData, true, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    Four of a Kind
                  </div>
                  <span>
                    {getCasinoPlExposureByRunner(
                      casinoPlData,
                      "Four of a Kind"
                    )}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row  ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Four of a Kind", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Four of a Kind", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity(
                        "Four of a Kind",
                        casinoData,
                        true,
                        true
                      )}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Four of a Kind", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Four of a Kind", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity(
                        "Four of a Kind",
                        casinoData,
                        true,
                        true
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-8"
                  >
                    Straight Flush
                  </div>
                  <span>
                    {getCasinoPlExposureByRunner(
                      casinoPlData,
                      "Straight Flush"
                    )}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-8 casino_odds_row  ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Straight Flush", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Straight Flush", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity(
                        "Straight Flush",
                        casinoData,
                        true,
                        true
                      )}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-8"
                    onClick={() =>
                      setBet("Straight Flush", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-8"
                    >
                      {getOddForEntity("Straight Flush", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      {getOddForEntity(
                        "Straight Flush",
                        casinoData,
                        true,
                        true
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="remark w-100 text-right pr-2 ng-tns-c1798067373-8"
        />
      </div>
    </CasinoPage>
  );
}
