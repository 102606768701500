import React, { useEffect, useState } from "react";
import CasinoPage from "./components/CasinoPage";
import "./index.scoped.css";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
export default function CasinoQueen() {
  const GAME_ID = "CASINO_QUEEN";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("DRAGON");
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);

  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.casinoQueen}
      GAME_ID={GAME_ID}
      gameName={"Casino Queen"}
      shortName={"queen"}
    >
      <div className="casino-detail">
        <div className="casino-table">
          <div className="casino-table-box">
            <div
              className={`casino-odd-box-container ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
            >
              <div className="casino-nation-name">Total 0</div>
              <div
                onClick={() =>
                  setBet("Total 0", casinoData, dispatch, "back")
                }
                className="casino-odds-box back"
              >
                <span className="casino-odds">
                  {getOddForEntity("Total 0", casinoData, true)}
                </span>
              </div>
              <div
                onClick={() => setBet("Total 0", casinoData, dispatch, "lay")}
                className="casino-odds-box lay"
              >
                <span className="casino-odds">
                  {getOddForEntity("Total 0", casinoData, false)}
                </span>
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Total 0")}
              </div>
            </div>
            <div
              className={`casino-odd-box-container ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
            >
              <div className="casino-nation-name">Total 1</div>
              <div
                onClick={() =>
                  setBet("Total 1", casinoData, dispatch, "back")
                }
                className="casino-odds-box back"
              >
                <span className="casino-odds">
                  {getOddForEntity("Total 1", casinoData, true)}
                </span>
              </div>
              <div
                onClick={() => setBet("Total 1", casinoData, dispatch, "lay")}
                className="casino-odds-box lay"
              >
                <span className="casino-odds">
                  {getOddForEntity("Total 1", casinoData, false)}
                </span>
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Total 1")}
              </div>
            </div>
            <div
              className={`casino-odd-box-container ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
            >
              <div className="casino-nation-name">Total 2</div>
              <div
                onClick={() =>
                  setBet("Total 2", casinoData, dispatch, "back")
                }
                className="casino-odds-box back"
              >
                <span className="casino-odds">
                  {getOddForEntity("Total 2", casinoData, true)}
                </span>
              </div>
              <div
                onClick={() => setBet("Total 2", casinoData, dispatch, "lay")}
                className="casino-odds-box lay"
              >
                <span className="casino-odds">
                  {getOddForEntity("Total 2", casinoData, false)}
                </span>
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Total 2")}
              </div>
            </div>
            <div
              className={`casino-odd-box-container ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
            >
              <div className="casino-nation-name">Total 3</div>
              <div
                onClick={() =>
                  setBet("Total 3", casinoData, dispatch, "back")
                }
                className="casino-odds-box back"
              >
                <span className="casino-odds">
                  {getOddForEntity("Total 3", casinoData, true)}
                </span>
              </div>
              <div
                onClick={() => setBet("Total 3", casinoData, dispatch, "lay")}
                className="casino-odds-box lay"
              >
                <span className="casino-odds">
                  {getOddForEntity("Total 3", casinoData, false)}
                </span>
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Total 3")}
              </div>
            </div>
          </div>
          <div className="casino-remark mt-1">
            <marquee scrollamount={3}>
              This is 21 cards game 2,3,4,5,6 x 4 =20 and 1 Queen. Minimum
              total 10 or queen is required to win.
            </marquee>
          </div>
        </div>
        <div className="casino-last-result-title">
          <span>Last Result</span>
          <span>
            <a href="/casino-results/queen">View All</a>
          </span>
        </div>
        <div className="casino-last-results">
          <span className="result result-b">2</span>
          <span className="result result-b">1</span>
          <span className="result result-b">1</span>
          <span className="result result-b">1</span>
          <span className="result result-b">0</span>
          <span className="result result-b">2</span>
          <span className="result result-b">0</span>
          <span className="result result-b">0</span>
          <span className="result result-b">3</span>
          <span className="result result-b">0</span>
        </div>
      </div>
    </CasinoPage>
  );
}
