import React, { useEffect, useState } from "react";

import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import CasinoPage from "./components/CasinoPage";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import "./index.scoped.css";
export default function Card32B() {
  const GAME_ID = "CARD32EU";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.thirtyTwoCardB}
      GAME_ID={GAME_ID}
      shortName={"cards32a cards32b"}
      gameName={"CARDS 32B"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-0 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-box ng-tns-c1798067373-0"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-none d-block ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min-max-dt20 ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        Min:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        {" "}
                        100 {/**/}
                        {/**/}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        Max:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        {" "}
                        500000 {/**/}
                        {/**/}
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-block d-none ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserange1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserange1"
                      className="minmax-btn ng-tns-c1798067373-0"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserange1"
                      className="range-collpase collapse ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          Min:
                        </b>{" "}
                        100 {/**/}
                        {/**/}
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {" "}
                          500000 {/**/}
                          {/**/}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0"
              >
                Back
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box lay ng-tns-c1798067373-0"
              >
                Lay
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    {" "}
                    Player 8{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 text-success"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(casinoPlData, "Player 8")}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 casino_odds_row ${
                    casinoData?.data?.response.autoTime < 5
                      ? "suspended-box"
                      : ""
                  } `}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Player 8", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 8", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity("Player 8", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Player 8", casinoData, dispatch, "lay")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 8", casinoData, false)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 8", casinoData, false, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    {" "}
                    Player 9{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 text-success"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(casinoPlData, "Player 9")}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 casino_odds_row ${
                    casinoData?.data?.response.autoTime < 5
                      ? "suspended-box"
                      : ""
                  } `}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Player 9", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 9", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity("Player 9", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Player 9", casinoData, dispatch, "lay")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 9", casinoData, false)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity("Player 9", casinoData, false, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    {" "}
                    Player 10{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 text-success"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(casinoPlData, "Player 10")}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 casino_odds_row ${
                    casinoData?.data?.response.autoTime < 5
                      ? "suspended-box"
                      : ""
                  } `}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Player 10", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 10", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity("Player 10", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Player 10", casinoData, dispatch, "lay")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 10", casinoData, false)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity("Player 10", casinoData, false, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    {" "}
                    Player 11{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 text-success"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(casinoPlData, "Player 11")}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 casino_odds_row ${
                    casinoData?.data?.response.autoTime < 5
                      ? "suspended-box"
                      : ""
                  } `}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Player 11", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 11", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity("Player 11", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Player 11", casinoData, dispatch, "lay")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 11", casinoData, false)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity("Player 11", casinoData, false, true)}
                    </p>
                  </div>
                </div>
              </div>
              {/**/}
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-none d-block ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min-max-dt20 ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        Min:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        {" "}
                        100 {/**/}
                        {/**/}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        Max:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        {" "}
                        500000 {/**/}
                        {/**/}
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-block d-none ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserange1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserange1"
                      className="minmax-btn ng-tns-c1798067373-0"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserange1"
                      className="range-collpase collapse ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          Min:
                        </b>{" "}
                        100 {/**/}
                        {/**/}
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {" "}
                          500000 {/**/}
                          {/**/}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0"
              >
                Odd
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0"
              >
                Even
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    Player 8
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino_odds_row ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box ng-star-inserted ${
                      casinoData?.data?.response.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() =>
                      setBet("Player 8 Odd", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 8 Odd", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Player 8 Odd"
                      )}
                    </p>
                  </div>
                  {/**/}
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box ng-star-inserted ${
                      casinoData?.data?.response.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() =>
                      setBet("Player 8 Even", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 8 Even", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Player 8 Even"
                      )}
                    </p>
                  </div>
                  {/**/}
                  {/**/}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    Player 9
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino_odds_row ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box ng-star-inserted ${
                      casinoData?.data?.response.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() =>
                      setBet("Player 9 Odd", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 9 Odd", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Player 9 Odd"
                      )}
                    </p>
                  </div>
                  {/**/}
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box ng-star-inserted ${
                      casinoData?.data?.response.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() =>
                      setBet("Player 9 Even", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 9 Even", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Player 9 Even"
                      )}
                    </p>
                  </div>
                  {/**/}
                  {/**/}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    Player 10
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino_odds_row ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box ng-star-inserted ${
                      casinoData?.data?.response.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() =>
                      setBet("Player 10 Odd", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 10 Odd", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Player 10 Odd"
                      )}
                    </p>
                  </div>
                  {/**/}
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box ng-star-inserted ${
                      casinoData?.data?.response.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() =>
                      setBet("Player 10 Even", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 10 Even", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Player 10 Even"
                      )}
                    </p>
                  </div>
                  {/**/}
                  {/**/}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    Player 11
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino_odds_row ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box ng-star-inserted ${
                      casinoData?.data?.response.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() =>
                      setBet("Player 11 Odd", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 11 Odd", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Player 11 Odd"
                      )}
                    </p>
                  </div>
                  {/**/}
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 back casino-odds-box ng-star-inserted ${
                      casinoData?.data?.response.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    onClick={() =>
                      setBet("Player 11 Even", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Player 11 Even", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Player 11 Even"
                      )}
                    </p>
                  </div>
                  {/**/}
                  {/**/}
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-none d-block ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min-max-dt20 ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        Min:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        {" "}
                        100 {/**/}
                        {/**/}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        Max:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        {" "}
                        500000 {/**/}
                        {/**/}
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-block d-none ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserange1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserange1"
                      className="minmax-btn ng-tns-c1798067373-0"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserange1"
                      className="range-collpase collapse ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          Min:
                        </b>{" "}
                        100 {/**/}
                        {/**/}
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {" "}
                          500000 {/**/}
                          {/**/}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0"
              >
                Back
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box lay ng-tns-c1798067373-0"
              >
                Lay
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    {" "}
                    Any Three Card Black{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 text-success"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Any Three Card Black"
                      )}{" "}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 casino_odds_row ${
                    casinoData?.data?.response.autoTime < 5
                      ? "suspended-box"
                      : ""
                  } `}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet(
                        "Any Three Card Black",
                        casinoData,
                        dispatch,
                        "back"
                      )
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity(
                        "Any Three Card Black",
                        casinoData,
                        true
                      )}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    />
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity(
                        "Any Three Card Black",
                        casinoData,
                        false
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    {" "}
                    Any Three Card Red{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 text-success"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Any Three Card Red"
                      )}{" "}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 casino_odds_row ${
                    casinoData?.data?.response.autoTime < 5
                      ? "suspended-box"
                      : ""
                  } `}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Any Three Card Red", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Any Three Card Red", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    />
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity("Any Three Card Red", casinoData, false)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    {" "}
                    Two Black Two Red{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 text-success"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Two Black Two Red"
                      )}{" "}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 casino_odds_row ${
                    casinoData?.data?.response.autoTime < 5
                      ? "suspended-box"
                      : ""
                  } `}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("Two Black Two Red", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("Two Black Two Red", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    />
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      {getOddForEntity("Two Black Two Red", casinoData, false)}
                    </p>
                  </div>
                </div>
              </div>
              {/**/}
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box cards32total ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-none d-block ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min-max-dt20 ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        Min:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        {" "}
                        100 {/**/}
                        {/**/}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        Max:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        {" "}
                        500000 {/**/}
                        {/**/}
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-block d-none ng-tns-c1798067373-0"
                >
                  {" "}
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserange1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserange1"
                      className="minmax-btn ng-tns-c1798067373-0"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserange1"
                      className="range-collpase collapse ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          Min:
                        </b>{" "}
                        100 {/**/}
                        {/**/}
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {" "}
                          500000 {/**/}
                          {/**/}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    {" "}
                    8 &amp; 9 Total{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 text-success"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "8 & 9 Total"
                      )}{" "}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 casino_odds_row ${
                    casinoData?.data?.response.autoTime < 5
                      ? "suspended-box"
                      : ""
                  } `}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back w-100 ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("8 & 9 Total", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("8 & 9 Total", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-0"
                  >
                    {" "}
                    10 &amp; 11 Total{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 text-success"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "10 & 11 Total"
                      )}{" "}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 casino_odds_row ${
                    casinoData?.data?.response.autoTime < 5
                      ? "suspended-box"
                      : ""
                  } `}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back w-100 ng-tns-c1798067373-0"
                    onClick={() =>
                      setBet("10 & 11 Total", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                    >
                      {getOddForEntity("10 & 11 Total", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                    </p>
                  </div>
                </div>
              </div>
              {/**/}
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-full-box mt-3 card32numbers ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="d-flex ng-tns-c1798067373-0"
            >
              <h4
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center mb-2 ng-tns-c1798067373-0"
              >
                <b
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  0
                </b>
              </h4>
              <div
                _ngcontent-ng-c1798067373=""
                className="min_max_collapse mt-1 me-1 ng-tns-c1798067373-0"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  data-bs-toggle="collapse"
                  href="#collapserange5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapserange4"
                  className="minmax-btn ng-tns-c1798067373-0"
                >
                  <i
                    _ngcontent-ng-c1798067373=""
                    className="fas fa-info-circle ng-tns-c1798067373-0"
                  />
                </a>
                <div
                  _ngcontent-ng-c1798067373=""
                  id="collapserange5"
                  className="range-collpase collapse ng-tns-c1798067373-0"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-0 ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Min:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      100 {/**/}
                      {/**/}
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-1 ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Max:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      500000 {/**/}
                      {/**/}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className={`ng-tns-c1798067373-0 card32numbers-container ${
                casinoData?.data?.response.autoTime < 5 ? "suspended-box" : ""
              }`}
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 1", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  1
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 1")}{" "}
                </p>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 2", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  2
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 2")}{" "}
                </p>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 3", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  3
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 3")}{" "}
                </p>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 4", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  4
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 4")}{" "}
                </p>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 5", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  5
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 5")}{" "}
                </p>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 6", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  6
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 6")}{" "}
                </p>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 7", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  7
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 7")}{" "}
                </p>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 8", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  8
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 8")}{" "}
                </p>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 9", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  9
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 9")}{" "}
                </p>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-0 ng-star-inserted"
                onClick={() => setBet("Single 0", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  0
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 text-success"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Single 0")}{" "}
                </p>
              </div>
              {/**/}
              {/**/}
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="remark text-right pr-2 w-100 ng-tns-c1798067373-0"
        />
      </div>
    </CasinoPage>
  );
}
