import React from "react";

export default function CasinoComingSoon() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        fontSize: "30px",
        textAlign: "center",
        width: "100%",
      }}
    >
      <h2>Coming Soon...</h2>
    </div>
  );
}
