import React, { useEffect, useState } from "react";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import "./index.scoped.css";
import CasinoPage from "./components/CasinoPage";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
export default function AndarBahar() {
  const GAME_ID = "AB_20";
  const VIDEO_ID = CASINO_VIDEO.anderBahr1;
  const dispatch = useDispatch();
  const [casinoPlData, setCasinoPlData] = useState([]);

  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );

  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      shortName={"ab ab3"}
      casino={casinoData?.data}
      VIDEO_ID={VIDEO_ID}
      GAME_ID={GAME_ID}
      gameName={"Andar Bahar"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-3 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="d-xl-block d-none ng-tns-c1798067373-3"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box ng-tns-c1798067373-3"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="andar-box ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="ab-title ng-tns-c1798067373-3"
              >
                ANDAR
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ab-cards ng-tns-c1798067373-3"
              >
                {CARD_ARRAY?.map((card) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    onClick={() =>
                      setBet(`Ander ${card}`, casinoData, dispatch)
                    }
                    className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className={`${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      } ng-tns-c1798067373-0`}
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${card}.webp`}
                      />
                    </div>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                    >
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        `Ander ` + card,
                        casinoData?.data?.response?.marketId
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="bahar-box ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="ab-title ng-tns-c1798067373-3"
              >
                BAHAR
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ab-cards ng-tns-c1798067373-3"
              >
                {CARD_ARRAY?.map((card) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    onClick={() =>
                      setBet(`Bahar ${card}`, casinoData, dispatch)
                    }
                    className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className={`${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      } ng-tns-c1798067373-0`}
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${card}.webp`}
                      />
                    </div>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                    >
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        `Bahar ` + card,
                        casinoData?.data?.response?.marketId
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="d-xl-none d-block mobileandarbahar w-100 ng-tns-c1798067373-3"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box ng-tns-c1798067373-3"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="andar-boxmob ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="andar_boxmob_title ng-tns-c1798067373-3"
              >
                Andar
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="andar-boxmob-slider ng-tns-c1798067373-3"
              >
                <button
                  _ngcontent-ng-c1798067373=""
                  type="button"
                  className="ab-pervious d-lg-none d-block ng-tns-c1798067373-3"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/left-arrow-black.webp"
                  />
                </button>
                <button
                  _ngcontent-ng-c1798067373=""
                  type="button"
                  className="ab-next d-lg-none d-block ng-tns-c1798067373-3"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/right_arrow_black.webp"
                  />
                </button>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="draggable ng-tns-c1798067373-3"
                >
                  {CARD_ARRAY?.map((item) => (
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-3"
                      >
                        <div
                          onClick={
                            casinoData?.data?.response?.autoTime < 5
                              ? () => {}
                              : () =>
                                  setBet(
                                    "Ander " + item,
                                    casinoData,
                                    dispatch
                                  )
                          }
                          _ngcontent-ng-c1798067373=""
                          className={`ng-tns-c1798067373-3  ${
                            casinoData?.data?.response?.autoTime < 5
                              ? "suspended-box"
                              : ""
                          }`}
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3 ng-star-inserted"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                          />
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-3"
                        >
                          {getCasinoPlExposureByRunner(
                            "Ander " + item,
                            casinoPlData,
                            casinoData?.data?.response?.marketId
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box ng-tns-c1798067373-3"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="lightgreen_box ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="andar_boxmob_title ng-tns-c1798067373-3"
              >
                Bahar
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="andar-boxmob-slider ng-tns-c1798067373-3"
              >
                <button
                  _ngcontent-ng-c1798067373=""
                  type="button"
                  className="ab-pervious d-lg-none d-block ng-tns-c1798067373-3"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/left-arrow-black.webp"
                  />
                </button>
                <button
                  _ngcontent-ng-c1798067373=""
                  type="button"
                  className="ab-next d-lg-none d-block ng-tns-c1798067373-3"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/right_arrow_black.webp"
                  />
                </button>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="draggable ng-tns-c1798067373-3"
                >
                  {CARD_ARRAY?.map((item) => (
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-3"
                      >
                        <div
                          onClick={
                            casinoData?.data?.response?.autoTime < 5
                              ? () => {}
                              : () =>
                                  setBet(
                                    "Bahar" + item,
                                    casinoData,
                                    dispatch
                                  )
                          }
                          _ngcontent-ng-c1798067373=""
                          className={`ng-tns-c1798067373-3  ${
                            casinoData?.data?.response?.autoTime < 5
                              ? "suspended-box"
                              : ""
                          }`}
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3 ng-star-inserted"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                          />
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-3"
                        >
                          {getCasinoPlExposureByRunner(
                            "Bahar " + item,
                            casinoPlData,
                            casinoData?.data?.response?.marketId
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
