import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import PlaceBet from "./components/PlaceBet";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import Timer from "./components/Timer";
import CasinoVideo from "./components/CasinoVideo";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import CasinoPage from "./components/CasinoPage";
export default function TeenpattiOneDay() {
  const GAME_ID = "TEEN";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.teenPatti1Day}
      GAME_ID={GAME_ID}
      shortName={"teenpatti1day"}
      gameName={"1 Day Teen Patti"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-9 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-box ng-tns-c1798067373-9"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box w-100 ng-tns-c1798067373-9"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-9"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-0 ng-tns-c1798067373-9"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      Min:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-9 ng-star-inserted"
                      >
                        {" "}
                        100{" "}
                      </span>
                      {/**/}
                      {/**/}
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-1 ng-tns-c1798067373-9"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      Max:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-9 ng-star-inserted"
                      >
                        {" "}
                        250000{" "}
                      </span>
                      {/**/}
                      {/**/}
                    </span>
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-9"
              >
                Back
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box lay ng-tns-c1798067373-9"
              >
                Lay
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-9"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-9"
                  >
                    {" "}
                    Player A{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {getCasinoPlExposureByRunner(casinoPlData, "Player A")}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-9 casino_odds_row  ${
                    casinoData?.data?.response.autoTime < 5 ||
                    getOddForEntity("Player A", casinoData, true) == 0
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-9"
                    onClick={() =>
                      setBet("Player A", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-9"
                    >
                      {getOddForEntity("Player A", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      {getOddForEntity("Player A", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-9"
                    onClick={() =>
                      setBet("Player A", casinoData, dispatch, "lay")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-9"
                    >
                      {getOddForEntity("Player A", casinoData, false)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      {getOddForEntity("Player A", casinoData, false, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-9"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-9"
                  >
                    {" "}
                    Player B{" "}
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {getCasinoPlExposureByRunner(casinoPlData, "Player B")}
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-9 casino_odds_row  ${
                    casinoData?.data?.response.autoTime < 5 ||
                    getOddForEntity("Player B", casinoData, true) == 0
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-9"
                    onClick={() =>
                      setBet("Player B", casinoData, dispatch, "back")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-9"
                    >
                      {getOddForEntity("Player B", casinoData, true)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      {getOddForEntity("Player B", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-9"
                    onClick={() =>
                      setBet("Player B", casinoData, dispatch, "lay")
                    }
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-9"
                    >
                      {getOddForEntity("Player B", casinoData, false)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      {" "}
                      {getOddForEntity("Player B", casinoData, false, true)}
                    </p>
                  </div>
                </div>
              </div>
              {/**/}
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
