import React, { useEffect } from "react";

export default function LadderModal({
  setShowModal,
  data,
  showLadderForMarket,
}) {
  const modalRef = React.useRef(null);

  if (!showLadderForMarket) {
    return null;
  }
  return (
    <div id="__BVID__30___BV_modal_outer_">
      <div
        id="__BVID__30"
        role="dialog"
        aria-labelledby="__BVID__30___BV_modal_title_"
        aria-describedby="__BVID__30___BV_modal_body_"
        className="modal fade show place-bet-modal"
        aria-modal="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-md">
          <span tabIndex={0} />
          <div
            role="document"
            id="__BVID__30___BV_modal_content_"
            tabIndex={-1}
            ref={modalRef}
            className="modal-content"
          >
            <header
              id="__BVID__30___BV_modal_header_"
              className="modal-header"
            >
              <h5 id="__BVID__30___BV_modal_title_" className="modal-title">
                Exposure
              </h5>
              <button
                onClick={setShowModal}
                type="button"
                aria-label="Close"
                className="close"
              >
                ×
              </button>
            </header>
            <div id="__BVID__30___BV_modal_body_" className="modal-body">
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Exposure</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      ?.sort((a, b) => {
                        return a.selectionId - b.selectionId;
                      })
                      ?.map((item) => (
                        <tr>
                          <td>{item.selectionId}</td>
                          <td
                            style={{
                              color: item.exposure < 0 ? "red" : "green",
                            }}
                          >
                            {item.exposure}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/**/}
          </div>
          <span tabIndex={0} />
        </div>
      </div>
      <div id="__BVID__30___BV_modal_backdrop_" className="modal-backdrop" />
    </div>
  );
}
