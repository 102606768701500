import React from "react";
import "./placebet.scoped.css";
import { useDispatch, useSelector } from "react-redux";
import { getProfit } from "../CasinoGeneric";
import { casinoBetAPI, getCasinoBetsAPI } from "../../../service/casino";
import toast from "react-hot-toast";
import { casinoPL, cricketMatchOddsPL } from "../../game/profitLoss";
import { FaSpinner } from "react-icons/fa";
export default function PlaceBet({ GAME_ID }) {
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const modalRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const buttons = [
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 500, label: "500" },
    { value: 1000, label: "1000" },
    { value: 10000, label: "10000" },
    { value: 20000, label: "20000" },
    { value: 50000, label: "50000" },
    { value: 100000, label: "100000" },
    { value: 200000, label: "200000" },
    { value: 500000, label: "500000" },
  ];
  let game = "casino";
  const placeBet = async () => {
    let delay = 1500;
    setIsLoading(true);
    setTimeout(async () => {
      let selectedAmount_ = selectedEventForBet?.amount;
      if (selectedEventForBet.selectedOdd <= 0) {
        toast.error("Please select a Valid Odd");
        setIsLoading(false);
        return;
      }
      let payload = {
        amount: selectedAmount_,
        marketId: selectedEventForBet?.marketId,
        profit: getProfit(selectedAmount_, game, selectedEventForBet)?.profit,
        loss: getProfit(selectedAmount_, game, selectedEventForBet)?.loss,
        runnerName: selectedEventForBet?.runnerName,
        selectionId: selectedEventForBet?.selectedId,
        casinoGames: GAME_ID,
        back: selectedEventForBet?.selectedOdd,
        type: selectedEventForBet?.type,
      };
      if (
        GAME_ID === "CRICKET_V3" ||
        GAME_ID === "SUPEROVER" ||
        GAME_ID === "RACE20"
      ) {
        console.log("selectedEventForBet", selectedEventForBet);
        let calculation = cricketMatchOddsPL(
          selectedEventForBet?.selectedOdd,
          selectedAmount_,
          selectedEventForBet?.type
        );
        payload.profit = parseFloat(calculation?.profit).toFixed(2);

        payload.loss = parseFloat(calculation?.loss).toFixed(2);
      }
      let { data, response, code } = await casinoBetAPI(payload);
      if (code == 200) {
        toast.success("Bet Placed");
      } else if (code == 500) {
        toast.error(response);
      } else if (code == "ERR_BAD_RESPONSE") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("Something Went Wrong");
      }

      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: null,
      });
    }, delay);
    setIsLoading(false);
  };
  const dispatch = useDispatch();
  const handleOutSideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: null,
      });
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
    };
  }, []);
  return (
    <div
      id="placebetmodal"
      tabIndex={-1}
      aria-labelledby="placebetmodalLabel"
      className={`modal fade ng-tns-c3502172659-0 ${selectedEventForBet &&
        "show"}`}
      aria-modal="true"
      role="dialog"
      style={{ display: selectedEventForBet ? "block" : "none" }}
    >
      <div className="modal-dialog ng-tns-c3502172659-0">
        <div ref={modalRef} className="modal-content ng-tns-c3502172659-0">
          <div className="modal-header ng-tns-c3502172659-0">
            <h5
              id="placebetmodalLabel"
              className="modal-title ng-tns-c3502172659-0"
            >
              Place Bet
            </h5>
            <button
              disabled={isLoading}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                dispatch({
                  type: "gameData/setSelectedEventForBet",
                  payload: null,
                });
              }}
              className="btn-close ng-tns-c3502172659-0"
            />
          </div>
          <div className="modal-body ng-tns-c3502172659-0">
            <div
              className={`place-bet-modal ng-tns-c3502172659-0 ${
                selectedEventForBet?.type
              }`}
            >
              <div className="row ng-tns-c3502172659-0">
                <div className="col-6 ng-tns-c3502172659-0">
                  <b className="ng-tns-c3502172659-0">
                    {selectedEventForBet?.runnerName}
                  </b>
                </div>
                <div className="col-6 ng-tns-c3502172659-0">
                  <div className="float-end ng-tns-c3502172659-0">
                    <button className="stakeactionminus btn ng-tns-c3502172659-0">
                      <span className="fa fa-minus ng-tns-c3502172659-0" />
                    </button>
                    <input
                      value={selectedEventForBet?.selectedOdd}
                      type="text"
                      className="stakeinput ng-tns-c3502172659-0 ng-untouched ng-pristine"
                      disabled={isLoading}
                    />
                    <button className="stakeactionminus btn ng-tns-c3502172659-0">
                      <span className="fa fa-plus ng-tns-c3502172659-0" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-2 ng-tns-c3502172659-0">
                <div className="col-4 ng-tns-c3502172659-0">
                  <input
                    onChange={(e) => {
                      dispatch({
                        type: "gameData/setSelectedEventForBet",
                        payload: {
                          ...selectedEventForBet,
                          amount: e.target?.value,
                          profit: getProfit(e.target?.value, "casino", {
                            selectedOdd: selectedEventForBet?.selectedOdd,
                            type: selectedEventForBet?.type,
                            amount: e.target?.value,
                          }).profit,
                          loss: getProfit(e.target?.value, "casino", {
                            selectedOdd: selectedEventForBet?.selectedOdd,
                            type: selectedEventForBet?.type,
                            amount: e.target?.value,
                          }).loss,
                        },
                      });
                    }}
                    type="number"
                    disabled={isLoading}
                    value={selectedEventForBet?.amount}
                    className="stakeinput w-100 ng-tns-c3502172659-0 ng-untouched ng-pristine ng-valid"
                  />
                </div>
                <div className="col-4 d-grid ps-0 ng-tns-c3502172659-0">
                  <button
                    disabled={isLoading}
                    onClick={placeBet}
                    className="btn btn-primary btn-block btn-loader ng-tns-c3502172659-0"
                  >
                    {isLoading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
                <div className="col-4 text-center pt-2 ng-tns-c3502172659-0">
                  <span className="ng-tns-c3502172659-0 ng-star-inserted">
                    {" "}
                    {selectedEventForBet?.profit}{" "}
                  </span>
                </div>
              </div>
              <div className="place-bet-buttons mt-2 ng-tns-c3502172659-0">
                {buttons?.map((item) => (
                  <button
                    disabled={isLoading}
                    onClick={() => {
                      dispatch({
                        type: "gameData/setSelectedEventForBet",
                        payload: {
                          ...selectedEventForBet,
                          amount: item?.value,
                          profit: getProfit(item?.value, "casino", {
                            selectedOdd: selectedEventForBet?.selectedOdd,
                            type: selectedEventForBet?.type,
                            amount: item?.value,
                          }).profit,
                          loss: getProfit(item?.value, "casino", {
                            selectedOdd: selectedEventForBet?.selectedOdd,
                            type: selectedEventForBet?.type,
                            amount: item?.value,
                          }).loss,
                        },
                      });
                    }}
                    className="btn btn-place-bet ng-tns-c3502172659-0 ng-star-inserted"
                  >
                    {" "}
                    {item?.label}{" "}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
