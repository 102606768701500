import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import CasinoPage from "./components/CasinoPage";
import { useDispatch } from "react-redux";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { useQuery } from "react-query";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
export default function TeenPattiTest() {
  const GAME_ID = "TEEN_9";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.teenPattiTest}
      GAME_ID={GAME_ID}
      shortName={"teenpattitest"}
      gameName={"Test Teen Patti"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-6 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-full-box ng-tns-c1798067373-6"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-header test-teenpatti-header-back ng-tns-c1798067373-6"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-nation-detail ng-tns-c1798067373-6"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-6"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="min_max_span ms-0 ng-tns-c1798067373-6"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    Min:
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    100 {/**/}
                    {/**/}
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="min_max_span ms-1 ng-tns-c1798067373-6"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    Max:
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    300000 {/**/}
                    {/**/}
                  </span>
                </span>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box back ng-tns-c1798067373-6"
            >
              Back
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-header ng-tns-c1798067373-6"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-nation-detail ng-tns-c1798067373-6"
            />
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box back ng-tns-c1798067373-6"
            >
              Tiger
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box back ng-tns-c1798067373-6"
            >
              Lion
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box back ng-tns-c1798067373-6"
            >
              Dragon
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-body ng-tns-c1798067373-6"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-6"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-6"
                >
                  Winner
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-6 casino_odds_row  ${
                  casinoData?.data?.response.autoTime < 5 ? "suspended-box" : ""
                }`}
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Tiger Winner", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Tiger Winner", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Tiger Winner"
                      )}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Lion Winner", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Lion Winner", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(casinoPlData, "Lion Winner")}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Dragon Winner", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Dragon Winner", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Dragon Winner"
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-6"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-6"
                >
                  Pair
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-6 casino_odds_row  ${
                  casinoData?.data?.response.autoTime < 5 ? "suspended-box" : ""
                }`}
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Tiger Pair", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Tiger Pair", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(casinoPlData, "Tiger Pair")}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Lion Pair", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Lion Pair", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(casinoPlData, "Lion Pair")}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Dragon Pair", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Dragon Pair", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(casinoPlData, "Dragon Pair")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-6"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-6"
                >
                  Flush
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-6 casino_odds_row  ${
                  casinoData?.data?.response.autoTime < 5 ? "suspended-box" : ""
                }`}
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Tiger Flush", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Tiger Flush", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    <span>
                      {getCasinoPlExposureByRunner(casinoPlData, "Tiger Flush")}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Lion Flush", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Lion Flush", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(casinoPlData, "Lion Flush")}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Dragon Flush", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Dragon Flush", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Dragon Flush"
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-6"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-6"
                >
                  Staright
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-6 casino_odds_row  ${
                  casinoData?.data?.response.autoTime < 5 ? "suspended-box" : ""
                }`}
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Tiger Straight", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Tiger Straight", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Tiger Straight"
                      )}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Lion Straight", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Lion Straight", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Lion Straight"
                      )}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Dragon Straight", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Dragon Straight", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Dragon Straight"
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-6"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-6"
                >
                  Trio
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-6 casino_odds_row  ${
                  casinoData?.data?.response.autoTime < 5 ? "suspended-box" : ""
                }`}
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Tiger Trio", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Tiger Trio", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(casinoPlData, "Tiger Trio")}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Lion Trio", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Lion Trio", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(casinoPlData, "Lion Trio")}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Dragon Trio", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Dragon Trio", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(casinoPlData, "Dragon Trio")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-6"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-6"
                >
                  Straight Flush
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-6 casino_odds_row  ${
                  casinoData?.data?.response.autoTime < 5 ? "suspended-box" : ""
                }`}
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Tiger Straight Flush", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Tiger Straight Flush", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Tiger Straight Flush"
                      )}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet("Lion Straight Flush", casinoData, dispatch, "back")
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Lion Straight Flush", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Lion Straight Flush"
                      )}
                    </span>
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-6"
                  onClick={() =>
                    setBet(
                      "Dragon Straight Flush",
                      casinoData,
                      dispatch,
                      "back"
                    )
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-6"
                  >
                    {getOddForEntity("Dragon Straight Flush", casinoData, true)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    {" "}
                    <span>
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Dragon Straight Flush"
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="remark text-right px-2 ng-tns-c1798067373-6"
        />
      </div>
    </CasinoPage>
  );
}
