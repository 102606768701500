import React, { useEffect, useState } from "react";
import CasinoPage from "./components/CasinoPage";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { CASINO_VIDEO, getOddForEntity, setBet } from "./CasinoGeneric";

export default function CricketMatch20() {
  const GAME_ID = "CRICKET_MATCH_20";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("DRAGON");
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.cricketMatch20}
      GAME_ID={GAME_ID}
      gameName={"Cricket Match 20"}
      shortName="cricket20"
    >
      <div className="casino-table">
        <div className="cricket20-container">
          <div className="cricket20-left">
            {[2, 3, 4, 5, 6].map((item, index) => (
              <div className="score-box">
                <div className="team-score">
                  <div>
                    <div className="text-center">
                      <b>Team A</b>
                    </div>
                    <div className="text-center">
                      <span className="ml-1">
                        {casinoData?.data?.response?.cards[1][0]}/
                        {casinoData?.data?.response?.cards[1][1]}{" "}
                      </span>
                      <span className="ml-2">
                        {" "}
                        {casinoData?.data?.response?.cards[1][2]} Over
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <b>Team B</b>
                    </div>
                    <div className="text-center">
                      <span className="ml-1">
                        {casinoData?.data?.response?.cards[2][0]}/
                        {casinoData?.data?.response?.cards[2][1]}{" "}
                      </span>
                      <span className="ml-2">
                        {" "}
                        {casinoData?.data?.response?.cards[2][2]} Over
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ball-icon">
                  <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/balls/cricket20/ball2.png" />
                </div>
                <div className="blbox">
                  <div
                    onClick={() =>
                      setBet("RUN " + item, casinoData, dispatch, "back")
                    }
                    className={`casino-odds-box back ${
                      casinoData?.data?.response?.autoTime < 5 ||
                      getOddForEntity("RUN " + item, casinoData, true) == 0.0
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <span className="casino-odds">
                      {getOddForEntity("RUN " + item, casinoData, true)}
                    </span>
                  </div>
                  <div
                    onClick={() =>
                      setBet("RUN " + item, casinoData, dispatch, "lay")
                    }
                    className={`casino-odds-box lay ${
                      casinoData?.data?.response?.autoTime < 5 ||
                      getOddForEntity("RUN " + item, casinoData, false) == 0.0
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <span className="casino-odds">
                      {getOddForEntity("RUN " + item, casinoData, false)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="cricket20-right">
            {[7, 8, 9, 10].map((item, index) => (
              <div className="score-box">
                <div className="team-score">
                  <div>
                    <div className="text-center">
                      <b>Team A</b>
                    </div>
                    <div className="text-center">
                      <span className="ml-1">
                        {casinoData?.data?.response?.cards[1][0]}/
                        {casinoData?.data?.response?.cards[1][1]}{" "}
                      </span>
                      <span className="ml-2">
                        {" "}
                        {casinoData?.data?.response?.cards[1][2]} Over
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <b>Team B</b>
                    </div>
                    <div className="text-center">
                      <span className="ml-1">
                        {casinoData?.data?.response?.cards[2][0]}/
                        {casinoData?.data?.response?.cards[2][1]}{" "}
                      </span>
                      <span className="ml-2">
                        {" "}
                        {casinoData?.data?.response?.cards[2][2]} Over
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ball-icon">
                  <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/balls/cricket20/ball2.png" />
                </div>
                <div className="blbox">
                  <div
                    onClick={() =>
                      setBet("RUN " + item, casinoData, dispatch, "back")
                    }
                    className={`casino-odds-box back ${
                      casinoData?.data?.response?.autoTime < 5 ||
                      getOddForEntity("RUN " + item, casinoData, true) == "0.00"
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <span className="casino-odds">
                      {getOddForEntity("RUN " + item, casinoData, true)}
                    </span>
                  </div>
                  <div
                    onClick={() =>
                      setBet("RUN " + item, casinoData, dispatch, "lay")
                    }
                    className={`casino-odds-box lay ${
                      casinoData?.data?.response?.autoTime < 5 ||
                      getOddForEntity("RUN " + item, casinoData, false) == 0.0
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <span className="casino-odds">
                      {getOddForEntity("RUN " + item, casinoData, false)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="casino-remark mt-1">
          <marquee scrollamount={3}>
            Team B Need 12 Runs to WIN Match. If The Score is Tie Team B will
            WIN.{" "}
          </marquee>
        </div>
      </div>
    </CasinoPage>
  );
}
