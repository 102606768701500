import React from "react";

export default function BallByBall() {
  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-0 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-0"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-0"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-0"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-0"
            >
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 active nav-link"
                >
                  Game
                </div>
              </li>
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 nav-link"
                >
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-0"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-0"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-0"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 ng-star-inserted"
                >
                  6326709624791
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-0"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-0"
            >
              {" "}
              Ball by Ball
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-0"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-0"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 ng-star-inserted"
                >
                  6326709624791
                </span>
                {/**/}
              </small>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0 ng-star-inserted"
              >
                {" "}
                | Min: 0 {/**/}
                {/**/} | Max: 0 {/**/}
                {/**/}
              </span>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-0 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-video ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="video-box-container ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-box ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                    src="https://alpha-n.qnsports.live/route/?id=3061"
                  />
                </div>
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0 ng-star-inserted"
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="clock ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="count-no ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="content ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="up ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              1
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="down ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                5
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip-card ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card-inner ng-tns-c1798067373-0 ng-trigger ng-trigger-flip"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-front ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="up ng-tns-c1798067373-0"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  5
                                </div>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-back ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="down ng-tns-c1798067373-0"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0"
                                  >
                                    1
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="count-no ng-tns-c1798067373-0 ng-star-inserted"
                  style={{ marginRight: 80 }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="content ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="up ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              0
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="down ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip-card ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card-inner ng-tns-c1798067373-0 ng-trigger ng-trigger-flip"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-front ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="up ng-tns-c1798067373-0"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  0
                                </div>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-back ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="down ng-tns-c1798067373-0"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0"
                                  >
                                    0
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                {/**/}
              </div>
            </div>
            {/**/}
            {/**/}
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-detail ng-tns-c1798067373-0"
            >
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-detail ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ball-by-ball ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="game-market market-6 ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-title ng-tns-c1798067373-0"
                    >
                      Runs
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-header row ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="market-row ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-nation-detail ng-tns-c1798067373-0"
                          />
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-odd-box back ng-tns-c1798067373-0"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              Back
                            </b>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="market-row ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-nation-detail ng-tns-c1798067373-0"
                          />
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-odd-box back ng-tns-c1798067373-0"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              Back
                            </b>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 d-none d-md-block ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="market-row ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-nation-detail ng-tns-c1798067373-0"
                          />
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-odd-box back ng-tns-c1798067373-0"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              Back
                            </b>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="fancy-min-max-box ng-tns-c1798067373-0"
                      />
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-body row ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          data-title="SUSPENDED"
                          className="fancy-market ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-row ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="market-nation-detail ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="market-nation-name pointer ng-tns-c1798067373-0"
                              >
                                0 Runs
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                id="book_1"
                                className="float-left book ng-tns-c1798067373-0"
                                style={{ color: "black" }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="blb-box ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="market-odd-box back ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-odd ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  2.18
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-volume ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="fancy-min-max ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Min: 50
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Max: 25000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          data-title="SUSPENDED"
                          className="fancy-market ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-row ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="market-nation-detail ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="market-nation-name pointer ng-tns-c1798067373-0"
                              >
                                1 Runs
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                id="book_1"
                                className="float-left book ng-tns-c1798067373-0"
                                style={{ color: "black" }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="blb-box ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="market-odd-box back ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-odd ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  2.63
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-volume ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="fancy-min-max ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Min: 50
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Max: 25000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          data-title="SUSPENDED"
                          className="fancy-market ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-row ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="market-nation-detail ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="market-nation-name pointer ng-tns-c1798067373-0"
                              >
                                2 Runs
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                id="book_1"
                                className="float-left book ng-tns-c1798067373-0"
                                style={{ color: "black" }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="blb-box ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="market-odd-box back ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-odd ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  7.86
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-volume ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="fancy-min-max ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Min: 50
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Max: 25000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          data-title="SUSPENDED"
                          className="fancy-market ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-row ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="market-nation-detail ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="market-nation-name pointer ng-tns-c1798067373-0"
                              >
                                3 Runs
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                id="book_1"
                                className="float-left book ng-tns-c1798067373-0"
                                style={{ color: "black" }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="blb-box ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="market-odd-box back ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-odd ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  11
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-volume ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="fancy-min-max ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Min: 50
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Max: 25000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          data-title="SUSPENDED"
                          className="fancy-market ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-row ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="market-nation-detail ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="market-nation-name pointer ng-tns-c1798067373-0"
                              >
                                4 Runs
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                id="book_1"
                                className="float-left book ng-tns-c1798067373-0"
                                style={{ color: "black" }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="blb-box ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="market-odd-box back ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-odd ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  6.54
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-volume ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="fancy-min-max ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Min: 50
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Max: 25000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          data-title="SUSPENDED"
                          className="fancy-market ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-row ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="market-nation-detail ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="market-nation-name pointer ng-tns-c1798067373-0"
                              >
                                6 Runs
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                id="book_1"
                                className="float-left book ng-tns-c1798067373-0"
                                style={{ color: "black" }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="blb-box ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="market-odd-box back ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-odd ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  11.86
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-volume ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="fancy-min-max ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Min: 50
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Max: 20000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          data-title="SUSPENDED"
                          className="fancy-market ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-row ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="market-nation-detail ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="market-nation-name pointer ng-tns-c1798067373-0"
                              >
                                Boundary
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                id="book_1"
                                className="float-left book ng-tns-c1798067373-0"
                                style={{ color: "black" }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="blb-box ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="market-odd-box back ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-odd ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  4.22
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-volume ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="fancy-min-max ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Min: 50
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Max: 25000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          data-title="SUSPENDED"
                          className="fancy-market ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-row ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="market-nation-detail ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="market-nation-name pointer ng-tns-c1798067373-0"
                              >
                                Wicket
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                id="book_1"
                                className="float-left book ng-tns-c1798067373-0"
                                style={{ color: "black" }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="blb-box ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="market-odd-box back ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-odd ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  6.92
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-volume ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="fancy-min-max ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Min: 50
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Max: 25000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-6 col-md-4 ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          data-title="SUSPENDED"
                          className="fancy-market ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="market-row ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="market-nation-detail ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="market-nation-name pointer ng-tns-c1798067373-0"
                              >
                                Extra Runs
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                id="book_1"
                                className="float-left book ng-tns-c1798067373-0"
                                style={{ color: "black" }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="blb-box ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="market-odd-box back ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-odd ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  8.61
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="market-volume ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  0{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="fancy-min-max ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Min: 50
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="w-100 d-block ng-tns-c1798067373-0"
                              >
                                Max: 25000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**/}
                      {/**/}
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-result-title ng-tns-c1798067373-0"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Last Result
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      View All
                    </a>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-results ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-0 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-0"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-0"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <div
        _ngcontent-ng-c1798067373=""
        className="sidebar right-sidebar casino-right-sidebar ng-tns-c1798067373-0"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c1798067373-0"
        >
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-0"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-0">
              Place Bet
            </h4>
          </div>
          {/**/}
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c1798067373-0"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-0"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-0">
              My Bet
            </h4>
            <a
              _ngcontent-ng-c1798067373=""
              className="sideviewmore ng-tns-c1798067373-0"
            >
              View more
            </a>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="my-bets ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive w-100 ng-tns-c1798067373-0"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-0"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-0"
        >
          <app-casino-game-rules
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-0"
            _nghost-ng-c1761710207=""
          >
            {/**/}
            {/**/}
            {/**/}
            {/**/}
          </app-casino-game-rules>
        </div>
      </div>
    </div>
  );
}
