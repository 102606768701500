import React from "react";

export default function GenericIndividualResult({ data, onClose }) {
  return (
    <div
      role="dialog"
      aria-modal="true"
      className="fade modal show"
      tabIndex={-1}
      style={{ paddingRight: 17, display: "block" }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">Andar Bahar Result</div>
            <button
              onClick={onClose}
              type="button"
              className="btn-close"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="casino-result-modal">
              <div className="casino-result-round-id">
                <span>
                  <b>Round Id: </b> 123241023112328
                </span>
                <span>
                  <b>Match Time: </b>23/10/2024 11:23:28 AM
                </span>
              </div>
              <div className="row mt-2 ab-result-container">
                <div className="col-md-12 text-center">
                  <div className="casino-result-cards ab-result-slider">
                    <div className="slick-slider slick-initialized">
                      <div className="slick-list">
                        <div
                          className="slick-track"
                          style={{
                            width: 945,
                            opacity: 1,
                            transform: "translate3d(0px, 0px, 0px)",
                          }}
                        >
                          <div
                            data-index={0}
                            className="slick-slide slick-active slick-current"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/2HH.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={1}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/5SS.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={2}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/7DD.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={3}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/7SS.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={4}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/9DD.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={5}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/3SS.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={6}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/2CC.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={7}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/KHH.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={8}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/ASS.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={9}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/4CC.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={10}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/4SS.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={11}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/5DD.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={12}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/JHH.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={13}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/JDD.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={14}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/6DD.jpg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center mt-1">
                  <div className="casino-result-cards ab-result-slider mb-2">
                    <div className="slick-slider slick-initialized">
                      <div className="slick-list">
                        <div
                          className="slick-track"
                          style={{
                            width: 945,
                            opacity: 1,
                            transform: "translate3d(0px, 0px, 0px)",
                          }}
                        >
                          <div
                            data-index={0}
                            className="slick-slide slick-active slick-current"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/QDD.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={1}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/8SS.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={2}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/3DD.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={3}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/QHH.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={4}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/4HH.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={5}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/JCC.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={6}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/5HH.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={7}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/8HH.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={8}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/2DD.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={9}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/3CC.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={10}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/9CC.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={11}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/ADD.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={12}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/10CC.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={13}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/9HH.jpg" />
                            </div>
                          </div>
                          <div
                            data-index={14}
                            className="slick-slide slick-active"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: 63 }}
                          >
                            <div>
                              <img src="https://versionobj.ecoassetsservice.com/v22/static/front/img/cards/QCC.jpg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2 justify-content-center">
                <div className="col-md-6">
                  <div className="casino-result-desc">
                    <div className="casino-result-desc-item">
                      <div>Winner</div>
                      <div>2,5,7,9,13,1,628,23,24,31,30</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
